:root {
  --grey: #555555;
  --light-orange: #EAB25D;
  --orange: #E29253;
  --dark-orange: #B47353;
  --dark-red: #795555;
}

.App {
  margin: 0 5% 5% 5%;
}

.center {
  text-align: center;
}

img.grayscale-selected {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 3.5+ */
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
}

img.grayscale {
  filter: none;
  -webkit-filter: grayscale(0%);
}

.fancy-font {
  font-family: 'Leckerli One' !important;
  color: var(--grey) !important;
}
.color-grey {
  color: var(--grey) !important;
}

.title {
  font-size: 8vh;
  padding: 1% 0;
}

.scroll {
  height: 75vh;
  overflow-y: auto;
}
.list-scroll {
  height: 65vh;
  overflow-y: auto;
}

.form-icon {
  background-color: transparent !important;
}

.form-icon:hover {
    color: var(--orange);
    text-shadow: 0 0 15px #3b5998;
    transition: all 0.5s ease;
}

.main-window {
  padding-top: 2%;
}

.search-add-to-playlist {
  margin: auto !important;
  margin-bottom: 5% !important;
}
.card-class {
  height: 100%;
}
.title-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-height: 1.5em;        /* fallback */
  height: 4.5em;       /* fallback */
}
